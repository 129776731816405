<template>
  <UserForm mode="Tambah" module="User"> </UserForm>
</template>

<script>
import UserForm from './form';

const UserAdd = {
  name: 'UserAdd',
  components: { UserForm },
};

export default UserAdd;
</script>
