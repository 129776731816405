<template>
  <sdPageHeader :title="title"> </sdPageHeader>
  <Main>
    <HorizontalFormStyleWrap>
      <sdCards headless>
        <a-form
          layout="horizontal"
          :model="formState"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          labelAlign="left"
          :rules="rules"
          @finish="handleSubmit"
        >
          <a-form-item name="name" label="Nama">
            <a-input v-model:value="formState.name" placeholder="Masukkan Nama" />
          </a-form-item>
          <a-form-item name="divisi_id" label="Direktorat/Divisi">
            <a-select
              placeholder="Pilih Direktorat/Divisi"
              v-model:value="formState.divisi_id"
              show-search
              :filter-option="filterSelect"
            >
              <a-select-option v-for="option in options.divisi" :key="option.value">
                {{ option.text }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item name="email" label="Email">
            <a-input type="email" v-model:value="formState.email" placeholder="Masukkan Email" />
          </a-form-item>
          <a-form-item name="roles" label="Role">
            <a-select
              placeholder="Pilih Role"
              v-model:value="formState.roles"
              show-search
              :filter-option="filterSelect"
              mode="multiple"
            >
              <a-select-option v-for="option in options.role" :key="option.value">
                {{ option.text }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-row>
            <a-col :lg="{ span: 18, offset: 6 }" :md="{ span: 15, offset: 9 }" :xs="{ span: 24, offset: 0 }">
              <div class="sDash_form-action">
                <sdButton class="sDash_form-action__btn" type="primary" size="large" htmlType="submit">
                  {{ isLoading ? 'Harap Menunggu...' : mode }}
                </sdButton>

                <router-link to="/master/user">
                  <sdButton class="sDash_form-action__btn" type="light" size="large" htmlType="button">
                    Batal
                  </sdButton>
                </router-link>
              </div>
            </a-col>
          </a-row>
        </a-form>
      </sdCards>
    </HorizontalFormStyleWrap>
  </Main>
</template>

<script>
import { HorizontalFormStyleWrap } from '../../../components/crud/style';
import { Main } from '../../styled';
import { useStore } from 'vuex';
import { reactive, computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { DataService } from '@/config/dataService/dataService';

const UserForm = {
  name: 'UserForm',
  components: { HorizontalFormStyleWrap, Main },
  data() {
    return {
      title: this.mode + ' ' + this.module,
    };
  },
  props: ['mode', 'module'],
  setup(props) {
    const { state, dispatch } = useStore();
    const { params } = useRoute();
    const isLoading = computed(() => state.crud.loading);
    const crud = computed(() => state.crud.data);

    const formState = reactive({
      name: '',
      divisi_id: undefined,
      email: '',
      roles: [],
    });

    const options = reactive({
      divisi: [],
      role: [],
    });

    const labelCol = {
      lg: 6,
      md: 9,
      xs: 24,
    };

    const wrapperCol = {
      lg: 18,
      md: 15,
      xs: 24,
    };

    const handleSubmit = values => {
      if (props.mode == 'Tambah') {
        dispatch('axiosCrudSubmitData', {
          url: 'user',
          data: values,
        });
      } else if (props.mode == 'Ubah') {
        dispatch('axiosDataUpdate', {
          id: params.id,
          url: 'user',
          data: values,
        });
      }
    };

    const rules = {
      name: [
        {
          required: true,
          message: 'Harap Diisi',
          trigger: 'blur',
        },
      ],
      email: [
        {
          required: true,
          message: 'Harap Diisi',
          trigger: 'blur',
          type: 'email',
        },
      ],
      role_id: [
        {
          required: true,
          message: 'Harap Dipilih',
          trigger: 'blur',
          type: 'number',
        },
      ],
    };

    const filterSelect = (input, option) => {
      return option.children[0].children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };

    onMounted(() => {
      DataService.get('options-divisi-all').then(response => {
        options.divisi = response.data;
      });

      DataService.get('options-role').then(response => {
        options.role = response.data;
      });

      if (props.mode == 'Ubah') {
        const data = {
          url: 'user',
          id: params.id,
        };

        dispatch('axiosSingleDataGet', data).then(() => {
          Object.keys(crud.value).forEach(key => {
            if (key == 'roles') {
              for (let ii = 0; ii < crud.value[key].length; ii++) {
                formState[key].push(crud.value[key][ii].id);
              }
            } else formState[key] = crud.value[key];
          });

          // formState['role_id'] = crud.value.roles.length ? crud.value.roles[0].id : null;
        });
      }
    });

    return {
      formState,
      labelCol,
      wrapperCol,
      handleSubmit,
      isLoading,
      rules,
      filterSelect,
      options,
    };
  },
};

export default UserForm;
</script>
